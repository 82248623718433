@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

/* Configurações Globais */
body, html {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

/* Corrige o layout dos contêineres principais */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-dots li button::before {
  font-size: 10px;
  color: #ec3237 !important;
}
